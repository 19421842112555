//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import qs from 'qs';
export default {
  name: 'EventAlarm',
  components: {},
  computed: {
    loadingText: function loadingText() {
      return "Data backup...".concat(this.progress);
    }
  },
  data: function data() {
    return {
      loading: false,
      confirm_text: null,
      async_waiting_cnt: 0,
      progress: "",
      place_list: [],
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      filters: {},
      count: 0,
      list: [],
      branch_list: [],
      FIELDS: [{
        key: 'device_name',
        label: this.$t('cloud.title.device')
      }, {
        key: 'event_name',
        label: this.$t('cloud.title.event_name')
      }, {
        key: 'ignoring_time',
        label: this.$t('cloud.title.ignoring_interval')
      }, {
        key: 'enabled',
        label: this.$t('cloud.title.enabled')
      }]
    };
  },
  mounted: function mounted() {
    this.initFetch();
  },
  methods: {
    initFetch: function initFetch() {
      // this.getBranchList();
      this.getList();
    },
    getTimeStr: function getTimeStr(second, min) {
      var time_type = this.$t('cloud.title.unit_second');
      var time = parseInt(second);
      var str = "";
      if (time % 60) str = time % 60 + '' + time_type + ' ' + str;

      if (time >= 60) {
        time_type = this.$t('cloud.title.unit_minute');
        time = parseInt(time / 60);
        if (time % 60) str = time % 60 + '' + time_type + ' ' + str;

        if (time >= 60) {
          time_type = this.$t('cloud.title.unit_hour');
          time = parseInt(time / 60);
          if (time % 60) str = time % 60 + '' + time_type + ' ' + str;
        }
      } //el.ignoring_time = time + " " + time_type;


      return str;
    },
    getList: function getList() {
      var _this = this;

      var query_string = '';
      var params = this.buildParam(10, this.pageCurrent); //if (params.bookmark === undefined && params.useless === undefined) return;

      console.log("jhyoo params");
      console.log(params);

      if (params) {
        query_string = '?' + qs.stringify(params);
      }

      this.list = [];
      console.log("query_string");
      console.log(query_string);
      axios.get("/api/alarm/ignoring-interval/".concat(query_string)).then(function (result) {
        _this.list = _this.$utility.copy(result.data.list);
        _this.pageTotal = result.data.page.total;
        _this.pageCurrent = result.data.page.current;
        _this.count = result.data.page.count;

        _this.list.map(function (el) {
          el.device_name = "".concat(el.bridge_name, " (").concat(el.bridge_mac, ")");
          el.ignoring_time = _this.getTimeStr(el.ignoring_second);
        });
      }).catch(function (error) {
        console.error(error);
      });
    },
    onUpdatePage: function onUpdatePage() {
      this.pageDirectSet = "" + this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet: function onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = "" + this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    buildParam: function buildParam(ipp, page) {
      var params = {
        ipp: ipp,
        page: page,
        bookmark: this.filters.bookmark,
        useless: this.filters.useless
      };
      if (this.filters.branch) params.branch = this.filters.branch;

      if (this.filters.event_types && this.filters.event_types.length) {
        params.event_types = this.filters.event_types.join(',');
      }

      if (this.filters.device) params.device = this.filters.device;
      if (this.filters.channels && this.filters.channels.length) params.channels = this.filters.channels.join(',');

      if (this.filters.ts_mode) {
        if (this.filters.ts_mode === 'today') {
          var now = new Date();
          now.setHours(0);
          now.setMinutes(0);
          now.setSeconds(0);
          now.setMilliseconds(0);
          params.sts = now.getTime();
        } else if (this.filters.ts_mode === 'd1') {
          var yesterday = new Date(Date.now() - 1000 * 60 * 60 * 24);
          yesterday.setHours(0);
          yesterday.setMinutes(0);
          yesterday.setSeconds(0);
          yesterday.setMilliseconds(0);
          params.sts = yesterday.getTime();
        } else if (this.filters.ts_mode === 'd2') {
          var _yesterday = new Date(Date.now() - 1000 * 60 * 60 * 24 * 2);

          _yesterday.setHours(0);

          _yesterday.setMinutes(0);

          _yesterday.setSeconds(0);

          _yesterday.setMilliseconds(0);

          params.sts = _yesterday.getTime();
        } else if (this.filters.ts_mode === 'h24') {
          params.sts = Date.now() - 1000 * 60 * 60 * 24;
        }
      } else if (this.filters.sts && this.filters.ets) {
        params.sts = this.filters.sts;
        params.ets = this.filters.ets;
      }

      if (this.filters.color_labels && this.filters.color_labels.length) {
        params.color_labels = this.filters.color_labels.join(",");
      }

      return params;
    },
    onPrev: function onPrev(alarm) {
      var curId = this.list.findIndex(function (el) {
        return el.id === alarm.id;
      });

      if (curId > 0) {//this.$refs.AlarmViewModal.open(this.list[curId-1]);
      }
    },
    onNext: function onNext(alarm) {
      var curId = this.list.findIndex(function (el) {
        return el.id === alarm.id;
      });

      if (curId < this.list.length - 1) {//this.$refs.AlarmViewModal.open(this.list[curId+1]);
      }
    }
  }
};